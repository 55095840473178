import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://59464071ef644f29a74eedcc8a0938eb@o517204.ingest.sentry.io/5624682",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});